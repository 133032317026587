import { UREncoder, URDecoder, UR } from "@apocentre/bc-ur";

const urEncode = (payload: any, fragmentSize: number): UREncoder => {
  const ur = UR.fromBuffer(Buffer.from(JSON.stringify(payload)));
  const encoder = new UREncoder(ur, fragmentSize);

  return encoder;
};

const getDecoder = (): URDecoder => new URDecoder();

export default {
  decoder: getDecoder,
  encode: urEncode,
};
