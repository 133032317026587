import "./App.css";
import Home from "./components/Home";
import Encoder from "./components/Encoder"
import Demo from "./components/Demo";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element:     <div className='App'>
      <Home />
      <hr />
      <Encoder />
    </div>,
    },
    {
      path: "/demo",
      element: <Demo/>,
    },
  ])

  return (
      <RouterProvider router={router} />
  )
}

export default App;
