import React, { useState, useRef, memo, useCallback } from "react";
import Qrcode from "./QRCode";
import ur from "../bcUr";
import FpsController from "./FpsController";

function Home() {
    const values = useRef({ fragmentSize: 90, payload: "" }).current;
    const [frameIndex, setFrameIndex] = useState(-1);
    const [framesCount, setFramesCount] = useState(0);
    const [urEncoder, setUrEncoder] = useState({});
    const [fps, setFps] = useState(8);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        try {
            if (values.payload) {
                const encoder = ur.encode(values.payload, values.fragmentSize);
                setUrEncoder(encoder);
                setFramesCount(encoder.fragmentsLength);
            }
        } catch (error) {
            console.warn("🚀 ~ useEffect ~ error", error);
        }
    }, []);

    return (
        <div className='container' style={styles.payloadHolder}>
            <div className='row'>
                <div className='col-12 col-lg-6 col-md-12' style={{ padding: 0 }}>
                    <div style={{ width: "100%", paddingLeft: 30, paddingRight: 30 }}>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label className='text-uppercase' style={styles.payloadLabel}>
                                    Please enter a payload
                                </label>
                                <textarea
                                    className='form-control'
                                    id='payload'
                                    placeholder='Enter payload'
                                    type='text'
                                    rows='10'
                                    defaultValue={values.payload}
                                    onChange={(e) => values.payload = e.target.value}
                                />
                            </div>
                            <button
                                type='submit'
                                className='btn btn-block text-uppercase'
                                style={styles.payloadButton}
                                color='#EFB23E'
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
                <div className='col-12  col-lg-6 col-md-12' style={{ padding: 0 }}>
                    <Qrcode fps={fps} framesCount={framesCount} frameIndex={frameIndex} setFrameIndex={setFrameIndex} urEncoder={urEncoder}/>
                </div>
            </div>
            <div style={{ marginBottom: 40 }}>
                <p style={{ color: "#01B96E", fontWeight: 500, fontSize: 40 }}>
                    {" "}
                    {frameIndex + 1} / {framesCount}
                </p>
            </div>
            <form>
                <div style={styles.fragmentHolder}>
                    <p style={styles.fragmentLabel}>Enter the fragment size:</p>
                    <input
                        className='form-control'
                        id='fragment'
                        placeholder='Enter fragment size'
                        type='text'
                        style={{ width: 200, textAlign: "center" }}
                        defaultValue={values.fragmentSize}
                        onChange={(e) => values.fragmentSize = e.target.value}
                    />
                </div>
            </form>
            <FpsController setFps={setFps} fps={fps} />
        </div>
    );
}

let styles = {
    payloadHolder: {
        marginTop: 100,
        marginBottom: 30,
        padding: 0,
    },
    payloadLabel: {
        fontWeight: "bold",
        color: "white",
        paddingBottom: 20,
    },
    payloadButton: {
        backgroundColor: "#EFB23E",
        color: "#121212",
        fontWeight: 500,
        marginBottom: 40,
    },
    fragmentHolder: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
    },
    fragmentLabel: {
        color: "white",
        paddingRight: 20,
        margin: 0,
        fontWeight: 500,
    },
    frameCounter: {
        fontSize: 36,
    },
};

export default memo(Home);

