import React, { useState } from "react";
import { qrEncoder } from "../../utils/qr";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";


function Encoder() {
    const [payload, setPayload] = useState("");
    const [payloadOperation, setPayloadOperation] = useState("encode");
    const [payloadType, setPayloadType] = useState("sync");
    const [payloadResult, setPayloadResult] = useState("");
    const [jsonError, setJsonError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(payload, payloadOperation, payloadType);
        try {
            if (payload) {
                const result = qrEncoder(payloadType, payloadOperation, payload);
                setPayloadResult(result);
            }
        } catch (error) {
            console.warn("🚀 ~ useEffect ~ error", error);
        }
    };

    const onInputChange = (value) => {
        try {
            JSON.parse(value);
            setJsonError('');
        } catch (error) {
            console.log(error.message);
            setJsonError(error.message);
        }
        setPayload(value);
    }

    const beautifyJSON = () => {
        if (jsonError.length) return;
        console.log('naber');

        try {
            const newPayload = JSON.stringify(JSON.parse(payload), null, 2);
            console.log(newPayload);
            setJsonError('');
            setPayload(newPayload)
        } catch (error) {
            console.log(error.message);
            setJsonError(error.message);
        }
    }


    return (
        <div className='container' style={styles.payloadHolder}>
            <div className='row'>
                <div className='col-12 col-lg-6 col-md-12' style={{ padding: 0 }}>
                    <div style={{ width: "100%", paddingLeft: 30, paddingRight: 30 }}>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label className='text-uppercase' style={styles.payloadLabel}>
                                    Please enter a payload for encoding/decoding
                                </label>

                                {!!jsonError.length && (
                                    <div style={styles.jsonError}>
                                        JSON ERROR: {jsonError}
                                    </div>
                                )}
                                <AceEditor
                                    width="100%"
                                    height="400px"
                                    mode="json"
                                    theme="monokai"
                                    name="code_editor"
                                    onChange={onInputChange}
                                    value={payload}
                                    editorProps={{ $blockScrolling: true }}
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                />
                            </div>
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-info btn-block"
                                onClick={beautifyJSON}
                                disabled={jsonError.length}
                                style={{ marginBottom: 20 }}>
                                Beautify JSON
                            </button>

                            <div className="form-group row">
                                <label htmlFor="operation" className="col-form-label col-sm-4" style={styles.payloadLabel}>Operation:</label>
                                <select className="form-control form-control-sm col-sm-6" name="operation" id="operation" value={payloadOperation} onChange={e => setPayloadOperation(e.target.value)}>
                                    <option value="encode">Encode</option>
                                    <option value="decode">Decode</option>
                                </select>
                            </div>

                            <div className="form-group row">
                                <label htmlFor="operation" className="col-form-label col-sm-4" style={styles.payloadLabel}>Type:</label>
                                <select className="form-control form-control-sm col-sm-6" name="type" id="type" value={payloadType} onChange={e => setPayloadType(e.target.value)}>
                                    <option value="sync">Sync</option>
                                    <option value="tx_sign">Transaction to send Zero</option>
                                    <option value="tx_signature">Signature coming from Zero</option>
                                </select>
                            </div>
                            <button
                                type='submit'
                                className='btn btn-block text-uppercase'
                                style={styles.payloadButton}
                                color='#EFB23E'
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
                <div className='col-12  col-lg-6 col-md-12' style={{ padding: 0 }}>
                    <div className='form-group'>
                        <label className='text-uppercase' style={styles.payloadLabel}>
                            Result
                        </label>
                        <textarea
                            className='form-control'
                            id='resultPayload'
                            placeholder=''
                            type='text'
                            rows='15'
                            value={payloadResult}
                            readOnly
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

let styles = {
    jsonError: {
        backgroundColor: "#484444",
        color: "red",
        fontSize: 12
    },
    payloadHolder: {
        marginTop: 100,
        marginBottom: 30,
        padding: 0,
    },
    payloadLabel: {
        fontWeight: "bold",
        color: "white",
        paddingBottom: 20,
    },
    payloadButton: {
        backgroundColor: "#EFB23E",
        color: "#121212",
        fontWeight: 500,
        marginBottom: 40,
    }
};

export default Encoder;
