import React, { memo, useCallback } from "react";


const FpsController = ({ fps, setFps }) => {
    const changeFps = useCallback((delta) => setFps(fps => Math.min(Math.max(0, fps + delta), 25)), []);
    const lowerFps = useCallback(() => changeFps(-1), [changeFps]);
    const higherFps = useCallback(() => changeFps(1), [changeFps]);

    return <div style={styles.parent}>
        <button
            style={styles.touchable}
            underlaycolor='rgba(100,100,100,0.2)'
            onClick={lowerFps}
        >
            <div style={styles.buttonMinus}>-</div>
        </button>
        <div style={styles.text}>FPS</div>
        <input
            readOnly
            className='form-control'
            id='fps'
            placeholder='Enter payload'
            type='text'
            keyboardtype='number-pad'
            style={{ ...styles.text, ...styles.textInput }}
            value={fps}
            // onChange={(change) => setFps()}
        />
        <button
            style={styles.touchable}
            underlaycolor='rgba(100,100,100,0.2)'
            onClick={higherFps}
        >
            <div style={styles.buttonPlus}>+</div>
        </button>
    </div>
}

let styles = {
    parent: {
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        textAlignVertical: "center",
        marginTop: 50,
    },
    text: {
        fontSize: 24,
        fontWeight: "bold",
        color: "#48769C",
    },
    textInput: {
        width: "15%",
        textAlign: "center",
    },
    touchable: {
        width: 60,
        zIndex: 250,
        height: 60,
        borderRadius: 50,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    buttonMinus: {
        color: "#F04151",
        fontSize: 40,
        fontWeight: "bold",
    },
    buttonPlus: {
        color: "#01B96E",
        fontSize: 40,
        fontWeight: "bold",
    },

}

export default memo(FpsController)