import React, { memo, useState, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCode = ({ framesCount = 0, fps = 8, setFrameIndex, urEncoder }) => {
    const [chunk, setChunk] = useState("");

    useEffect(() => {
        if (framesCount) {
            setFrameIndex(0);
        } else {
            setFrameIndex(-1);
        }
    }, [urEncoder, framesCount]);

    useEffect(() => {
        const timerId = setInterval(() => {
            setFrameIndex((frameIndex) => framesCount ? ((frameIndex + 1) % framesCount) : -1);
            setChunk(framesCount ? urEncoder.nextPart() : "")
        }, 1000 / fps);
        return () => clearInterval(timerId);
    }, [urEncoder, fps, framesCount]);

    return <QRCodeCanvas
        style={styles.qrcode}
        value={chunk}
        size={350}
        level='L'
    />
}

const styles = {
    qrcode: {
        padding: 15,
        backgroundColor: "white"
    },
}

export default memo(QRCode);