import { gzipSync, unzipSync } from 'zlib';
import crypto from 'crypto';


function qrEncoder(type, operation, data, version = 1) {
  const MAX_JSON_PARSE = 10;

  try {
    data = JSON.parse(data);
  } catch {
    alert(`Can't parse json data`);
    return;
  }

  // Encode to Ngrave QR payload
  if (operation === 'encode') {
    let payload = JSON.stringify(data);

    // Gzip
    payload = gzipSync(payload).toString('base64');

    // MD5
    const md5Hash = crypto.createHash('md5').update(payload, 'utf8').digest("hex");

    const QRPayload = {
      version,
      type,
      md5: md5Hash,
      data: payload,
    }

    return JSON.stringify(QRPayload, null, 2);
  }

  if (operation === 'decode') {
    try {

      let payload = data;

      // Sometimes there are too much escape chars
      for (let i = 0; i < MAX_JSON_PARSE; i++) {
        if (typeof payload == "string") payload = JSON.parse(payload);
        else break;
      }

      // Unzip data
      const buffer = Buffer.from(payload.data, 'base64');
      const unzipped = unzipSync(buffer).toString('utf8');

      let parsedData = unzipped;
      for (let i = 0; i < MAX_JSON_PARSE; i++) {
        if (typeof parsedData == "string") parsedData = JSON.parse(parsedData);
        else break;
      }

      return JSON.stringify(parsedData, null, 2);
    }
    catch {
      alert("Cannot decode the payload");
    }
  }

  return '';

}

export { qrEncoder }