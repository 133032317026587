import React, { useState, useEffect } from "react";
import Qrcode from "./../Home/QRCode";
import ur from "../bcUr";

const payload = {
  version: 1,
  type: "sync",
  md5: "0cea8d34fbcb231611d706239ad53293",
  data: "H4sIAAAAAAAAA+2UyW7bMBCG30XnHEhxndy4Oq0V1668F0Uga7GUxHJc2/ES5N3LtOgCdD30WIDAkANw5uf3E/MUbU9tftMU0WVUlKt1dBHl66bdRpfvniL9qk9pdIkuokN2f1/uPmezPF/v2y+HovhQbsM+wn4xuUsO5fR43mRwGPVH9ZxMrBoP4BH43DYq1G7aojx+qviwX9w3+V15ClcRMc4xCghTkCo2McTUcuyJM9ZZoSixHHEhPTExJyApcxojLoVhEFsDYKLn919E3nzrcQxNQvmXwI3okFFHm+No1+3Z/ms6q/crbwdJasacD5slMbMk7UzKxSjTnXgxOx3qlMvBLeDp/hGOq4PY765SeXK7qtqr+WZ4qLZLzbpwPlVabbJyOjgOxLB60fJ88RUe/zt66CgQYdh6B05hwFjyOIBgsRRMEWKksoZhow39DUUnnRDYAXAnpVNGSnDAseGESe29jjURgTRVDmmiDI1BUeQRRx5jj0D/maJ+WJ6WG0Ub0u32nDsnZNwuuqt0tCny8Zwkt769rtfX51lvdt0f3C7tcfowv67wrvcBdnzbb67ScVsNoZ8+YnPejO/qfQfUgair9O0Vh0V/tW5prZuJ/JFim63KoCN51esGBrv1XdnefA+Qhd+DUQCQVZBnnPGskhQVVSVJKWNOy7wCyfMs+m/IPzVklNrhTw0pshyLCpgs4rJkMcniGPGwACgDkWNSSILLXPw35B8YgoH+FUb3JuVvWVr7pOK7ZXICvM43fuDrrJrMJv3bDp2axyxP8kW9KYfFgHeSvf410dhAGMnaWCk8t1oKamUINLCTmGsUcmGqE6cZGGOZskQIzzSy2nAFQvvfEn154vvnjxBIKlWkBgAA",
};

function Demo() {
  const [frameIndex, setFrameIndex] = useState(-1);
  const [urEncoder, setUrEncoder] = useState({});

  useEffect(() => {
    const encoder = ur.encode(payload, 90);
    setUrEncoder(encoder);
  }, []);

  return (
    <div style={{ flex: 1, padding: "50px"}}>
      <Qrcode
        fps={8}
        framesCount={90}
        frameIndex={frameIndex}
        setFrameIndex={setFrameIndex}
        urEncoder={urEncoder}
      />
    </div>
  );
}

export default Demo;
